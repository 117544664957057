exports.onClientEntry = () => {
  const { search } = window.location
  const query = search.replace('?', '').trim()
  const adRef = {}
  const paramsSplit = query.split('&')
  const params = paramsSplit[0] === '' ? [] : paramsSplit
  params.map(param => {
    const p = param.split('=')
    const key = p[0]
    const value = p.length > 0 ? p[1] : ''
    adRef[key] = value
    return param
  })
  if (window.sessionStorage && !!params.length) {
    window.sessionStorage.setItem('rFprAn', JSON.stringify(adRef))
  }
}
